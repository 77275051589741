import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the CSS styles
import './App.css';
import InteractiveBox from './InteractiveBox'; // Adjust the path as needed
import InteractiveBox2 from './InteractiveBox2'; // Adjust the path as needed




function App() {
  
  useEffect(() => {
    AOS.init({
      // You can also pass in options
      mirror: true,
      duration: 2000, // values from 0 to 3000, with step 50ms
      once: false, // whether animation should happen only once - while scrolling down
    });
  }, []);

  // Function to handle smooth scrolling
  const scrollToSection = (e) => {
    e.preventDefault();
    const href = e.currentTarget.getAttribute('href');
    const offsetTop = document.querySelector(href).offsetTop;

    window.scrollTo({
      top: offsetTop,
      behavior: "smooth"
    });
    
  };

  return (
    <div className="App">
      <header className="App-header">
      <img src="/SM1.5.jpg" alt="Logo" className="App-logo" /> {/* Add this line for the logo */}
  
        <h2>Software Developer @IBM</h2>
        <nav>
          <ul className="header-nav">
            <li><a href="#about" onClick={scrollToSection}>About</a></li>
            <li><a href="#experience" onClick={scrollToSection}>Experience</a></li>
            <li><a href="#education" onClick={scrollToSection}>Education</a></li>
            <li><a href="#skills" onClick={scrollToSection}>Skills</a></li>
            <li><a href="#projects" onClick={scrollToSection}>Projects</a></li>
            <li><a href="#contact" onClick={scrollToSection}>Contact</a></li>
          </ul>
        </nav>
      </header>
      <main>
        {/* Introduction or About Me Section */}
        <section id="about" data-aos="fade-down">
        <div class="about">
        <h2>About Me</h2>
        <ul>
        <li>
          
            Welcome! I'm a passionate software developer currently working with IBM's z/VM system team. My role primarily involves creating, executing, and refining automation processes that manage hundreds to thousands of Linux VMs. This work is crucial for testing the robustness of workloads on Mainframe systems. With a strong foundation in software engineering and a keen interest in system architecture, I thrive on tackling complex technical challenges and optimizing performance.
        </li>
        <li>
            Beyond my professional role, I am deeply interested in front-end web development. This website, which showcases my resume, is a testament to my journey in mastering front-end technologies. I designed and developed it from the ground up, using it as a platform to not only share my professional experiences but also to continually refine my skills in web design and user experience. Whether in my professional endeavors or personal projects, I am committed to lifelong learning and constant improvement.
        </li>
        </ul>
    </div>
        </section>

        {/* Experience Section */}
        <section id="experience" data-aos="fade-left">
        <h2>Professional Experience</h2>
      
         <div class="job">
         <h3>Backend Software Developer / z/VM System Tester - IBM</h3>
         <p class="job-duration">January 2022 – Present, Poughkeepsie, NY</p>
         <ul>
         <li>Defines testing plans based on new code changes, ensuring comprehensive coverage and quality assurance.</li>
         <li>Deploys 1000’s of Linux virtual machines to push Memory, Paging, I/O, and CPU workloads for testing on the Mainframe. 
</li>
         <li>Owned testing for line items while creating reports for z/VM for software and hardware developers.</li>
         <li>Developed a spaCy machine learning test suite and a TensorFlow workload.</li>
         <li>Improved automation on z/VM and Linux side of testing using ReXX, Bash, and Python scripts.</li>
         <li>Opened firmware and software bugs for Linux Secure Boot loader and NVMe Paging Line Item. </li>
         <li>Monitored and tested workloads for new software pushes utilizing CI/CD methods.</li>
         <li>System administrator for an 8-member SSI VM system, applying updates and maintaining system integrity. </li>
         </ul>
        </div>

        <div class="job">
         <h3>IBM Hardware Test Technician - CTG</h3>
         <p class="job-duration">June 2021– December 2021, Poughkeepsie, NY</p>
         <ul>
         <li>Swapped and upgraded Z System processing units and memory components.</li>
         <li>Used chain of custody software to track and trace mainframe accessories and hardware.</li>
         <li>Led hardware swaps which requiring prompt communication and timing with other Z System teams.</li>
       </ul>
        </div>
        </section>


        <section id="leadership" data-aos="fade-right">
        <h2>Leadership Experience</h2>


        <div class="leadership-role">
          <h3>Lead Admin for Eight Member SSI Environment - IBM</h3>
          <p class="leadership-duration">July 2023– October 2023, Poughkeepsie, NY</p>
          <ul>
          <li>Owned the process of migration of I/O and networking devices to a larger SSI cluster.</li>
         <li>Created a front-end website to manage and track performance of the eight Member SSI cluster.</li>
        <li>Taught new-hires z/VM system environment management ideals and styles.</li>
        <li>Facilitated meetings for new-hires teaching z/VM, ReXX and Linux coding and debugging techniques.</li>
         </ul>
         </div>
       </section>


        {/* Education Section */}
        <section id="education" data-aos="fade-up">
        <h2>Education</h2>
        <div class="education">
         <h3>Bachelor of Science in Computer Science</h3>
         <p>SUNY New Paltz, New Paltz, NY - December 2020</p>
         </div>
        </section>


        {/* Skills Section */}
        <section id="skills" data-aos="fade-up">
         <h2>Skills, Activities & Interests</h2>
         <div class="skills">
         <h3>Computer Skills</h3>
         <p>Java, C, C#, Python, Assembly Language, JavaScript (Vue.js/React), Node.js, REXX, Bash</p>
        </div>
        <div class="interests">
          <h3>Activities & Interests</h3>
         <p>Camping, Hiking, Machine-Learning, Ethical Regenerative Agriculture, Neurodiversity, Yoga, Mycology, Meditation</p>
        </div>
        </section>


        {/* Projects Section */}
        <section id="projects" data-aos="fade-up" data-aos-offset="100">
          <h2>About This Website</h2>
          <InteractiveBox />
        </section>

        <section id="projects" data-aos="fade-up" data-aos-offset="100">
          <h2>Projects</h2>
          <InteractiveBox2 />
        </section>

        {/* Contact Section */}
        <section id="contact" data-aos="fade-up">
        <h2>Contact Me</h2>
        <div className="contact-icons">
         <a href="mailto:sam.g.morrone@gmail.com" target="_blank" rel="noopener noreferrer" title="Email Me">
         <i class="fa fa-envelope"></i>
          </a>
          <a href="https://www.linkedin.com/in/sam-morrone-3686b311b/" target="_blank" rel="noopener noreferrer" title="LinkedIn Profile">
          <i className="fab fa-linkedin"></i>
        </a>
      </div>
        </section>
      </main>
      <footer>
        {/* Footer content like social media links */}
        <p>© 2023 Samuel Morrone. All Rights Reserved</p>
      </footer>
    </div>
  );
}

export default App;
